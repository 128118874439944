<template>
	<errorContainer :error="error" :warning="warning">
		<transition name="slide">
			<div>
				<b-card no-body>
					<b-card-header>
						<b-row>
							<b-col cols="6">
								<h4>{{ action === 0 ? FormMSG(2, 'Add Commission') : FormMSG(3, 'Update Commission') }}</h4>
							</b-col>
							<b-col cols="6" class="pull-right">
								<b-button
									:style="`${!$screen.md ? 'width:100%' : ''}`"
									@click="backToList"
									variant="success"
									size="md"
									class="btn btn-primary btn-md float-right"
								>
									{{ FormMSG(1, 'Back to list') }}&nbsp;<i class="icon-action-undo"></i>
								</b-button>
							</b-col>
						</b-row>
					</b-card-header>
					<b-card-body>
						<b-form @submit.prevent="onSubmit">
							<b-row>
								<b-col cols="9">
									<b-form-group :label="FormMSG(4, 'Name')" label-for="name" :label-cols="3" :horizontal="true">
										<b-form-input
											v-model.trim="commission.name"
											type="text"
											:placeholder="FormMSG(5, 'Your commission name...')"
											:class="{ 'is-invalid': isSubmitted && $v.commission.name.$error }"
										></b-form-input>
										<div v-if="isSubmitted && !$v.commission.name.required" class="invalid-feedback">
											{{ FormMSG(16, 'Please, length should be at least 2') }}
										</div>
									</b-form-group>
									<b-form-group :label="FormMSG(6, 'Description')" label-for="Description" :label-cols="3" :horizontal="true">
										<b-form-textarea
											v-model="commission.description"
											type="text"
											:placeholder="FormMSG(7, 'Commission description...')"
											rows="5"
											:class="{ 'is-invalid': isSubmitted && $v.commission.description.$error }"
										/>
										<div v-if="isSubmitted && !$v.commission.description.required" class="invalid-feedback">
											{{ FormMSG(17, 'Please, enter some details') }}
										</div>
									</b-form-group>
									<b-form-group :label="FormMSG(8, 'Web Site')" label-for="webSite" :label-cols="3" :horizontal="true">
										<b-form-input v-model="commission.webSite" type="text" :placeholder="FormMSG(9, 'https://...')"></b-form-input>
									</b-form-group>
									<b-form-group :label="FormMSG(12, 'Contacts')" label-for="contacts" :label-cols="3" :horizontal="true" class="clearfix">
										<contact-selector
											:supplier-id="id"
											:list-label="FormMSG(13, 'Manage')"
											:placeholder="FormMSG(14, 'Choose a contact ...')"
											:modal-title="FormMSG(15, 'Manage Contact')"
											:error-message="FormMSG(18, 'Please, enter one or more contact(s)')"
											:show-default-label="false"
											:is-submitted="isSubmitted"
											@contact-selector:invalid="isInvalidateFields"
											@contact-selector:contacts="onSelectedContact"
										/>
									</b-form-group>
									<div class="w-100">
										<b-col cols="3" class="pull-right px-0">
											<b-form-group>
												<b-button type="submit" variant="primary" size="md" block :disabled="disabled">
													<span>{{ FormMSG(11, 'Save') }}</span>
												</b-button>
											</b-form-group>
										</b-col>
									</div>
								</b-col>
								<b-col cols="3" class="text-center">
									<div class="px-4">
										<b-form-group v-model="commission.xidLogo">
											<b-img :src="showPicture(commission.xidLogo)" fluid alt="logo" width="128" height="128" />
										</b-form-group>
										<b-form-group>
											<b-button type="button" variant="outline-primary" size="md" @click="openLogoModal" block>
												<span>{{ FormMSG(10, 'Your logo') }}</span>
											</b-button>
										</b-form-group>
									</div>
								</b-col>
							</b-row>
						</b-form>
					</b-card-body>
				</b-card>
				<logo
					v-model="isOpenLogoModal"
					:supplier-attribute-id="commission.id"
					image-child-sub-type="filmCommission"
					@supplier-attribute-logo:hidden="onHideLogoModal"
					@supplier-attribute-logo:added="onAddLogoModal"
					@supplier-attribute-logo:removed="onRemoveLogoModal"
				/>
			</div>
		</transition>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import ContactSelector from '@/components/Contact/Selector';
import Logo from '@/components/SupplierAttribute/LogoModal';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { addUpdateFilmCommission, getFilmCommission, delFilmCommission } from '@/cruds/film-commission.crud';
import globalMixin from '@/mixins/global.mixin';
import { isNil } from '@/shared/utils';

export default {
	name: 'FilmCommissionForm',
	mixins: [languageMessages, validationMixin, globalMixin],
	components: {
		ContactSelector,
		Logo
	},
	data() {
		return {
			error: {},
			warning: '',
			id: 0,
			action: 0,
			commission: {},
			isOpenLogoModal: false,
			isSubmitted: false,
			isInvalid: false,
			selectedContact: [],
			disabled: false,
			logoSrc: ''
		};
	},
	async created() {
		const { action, id } = this.$route.params;
		this.id = id;

		if (action === 'n') {
			this.action = 0;
		} else if (action === 'u') {
			this.action = 1;
			this.commission = await getFilmCommission(this.id);
		}
		this.onBackBrowser(this.action, this.id);
	},
	methods: {
		backToList() {
			if (this.action === 0) {
				this.$bvModal
					.msgBoxConfirm(this.FormMSG(19, 'Please confirm if you want to reject this data.'), {
						title: this.FormMSG(20, 'Confirm'),
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: this.FormMSG(21, 'YES'),
						cancelTitle: this.FormMSG(22, 'NO'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false,
						noCloseOnBackdrop: true
					})
					.then(async (v) => {
						if (v) {
							await delFilmCommission(this.id).then((result) => {
								if (result) {
									this.$router.push('/film-commission');
								}
							});
						}
					})
					.catch((err) => console.log(err));
			} else if (this.action === 1) {
				let path = '/film-commission';
				this.$router.push({ path });
			}
		},
		backToListSuccess(success = 0) {
			let path = '/film-commission';
			path += `?s=${success}`;

			this.$router.push({ path });
		},
		async asyncIsinvalid() {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve(this.isInvalid);
					reject(false);
				}, 250);
			});
		},
		async onSubmit(e) {
			e.preventDefault();
			this.isSubmitted = true;

			const isInvalid = await this.asyncIsinvalid();
			// console.log(isInvalid);

			this.$v.$touch();
			if (this.$v.$invalid || isInvalid) {
				return;
			}

			this.disabled = true;

			const commission = {
				...this.commission
			};

			await addUpdateFilmCommission(commission, this.id).then((record) => {
				if (record) {
					if (this.action === 0) {
						this.backToListSuccess();
					} else if (this.action === 1) {
						this.createToastForMobile(this.FormMSG(23, 'Success'), this.FormMSG(24, 'Film commission updated successfully'), '');
					}
					this.isSubmitted = false;
					this.disabled = false;
				}
			});
		},
		openLogoModal() {
			this.isOpenLogoModal = true;
		},
		onHideLogoModal(payload) {
			this.isOpenLogoModal = false;
		},
		async onAddLogoModal(payload) {
			await addUpdateFilmCommission(
				{
					xidLogo: payload.xid
				},
				this.id
			).then((record) => {
				if (record) {
					this.commission.xidLogo = payload.xid;
				}
			});
		},
		async onRemoveLogoModal(payload) {
			await addUpdateFilmCommission(
				{
					xidLogo: ''
				},
				this.id
			).then((record) => {
				if (record) {
					this.commission.xidLogo = '';
				}
			});
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
		},
		onSelectedContact(payload) {
			this.selectedContact = payload;
		},
		onBackBrowser(action, id) {
			window.onhashchange = () => {
				if (!window.innerDocClick) {
					if (action === 0) {
						let path = '/film-commission';
						path += `?s=1&id=${id}`;

						this.$router.push({ path });
					}
				}
			};
		},
		showPicture(picture) {
			let src = 'img/brand/add-image-photo-icon.png';

			if (!isNil(picture) && picture.length > 0) {
				src = process.env.VUE_APP_GQL + '/images/' + picture;
			}

			return src;
		}
	},
	validations: {
		commission: {
			name: {
				required,
				min: minLength(2)
			},
			description: {
				required,
				min: minLength(4)
			}
		}
	}
};
</script>
