import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

/**
 * @param {String|Number} filmCommissionId
 * @param {Object} updatedFilmCommission
 */
export const addUpdateFilmCommission = async (updatedFilmCommission = {}, filmCommissionId = 0) => {
	try {
		const mutation = gql`
			mutation ($filmCommissionId: ID!, $updatedFilmCommission: FilmCommissionInput!) {
				AddUpdFilmCommission(FilmCommissionId: $filmCommissionId, UpdatedFilmCommission: $updatedFilmCommission) {
					id
					name
					description
					webSite
					xidLogo
					filmCommissionContact {
						id
						name
						firstName
						phone
						email
						function
					}
				}
			}
		`;
		const {
			data: { AddUpdFilmCommission }
		} = await apollo.mutate({
			mutation,
			variables: {
				filmCommissionId: parseInt(filmCommissionId, 10),
				updatedFilmCommission
			}
		});

		return AddUpdFilmCommission;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} filmCommissionId
 */
export const delFilmCommission = async (filmCommissionId) => {
	try {
		const mutation = gql`
			mutation ($filmCommissionId: ID!) {
				DelFilmCommission(FilmCommissionId: $filmCommissionId)
			}
		`;
		const {
			data: { DelFilmCommission }
		} = await apollo.mutate({
			mutation,
			variables: {
				filmCommissionId: parseInt(filmCommissionId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return DelFilmCommission;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} filmCommissionId
 */
export const getFilmCommission = async (filmCommissionId) => {
	try {
		const {
			data: { GetFilmCommission }
		} = await apollo.query({
			query: gql`
				query ($filmCommissionId: ID!) {
					GetFilmCommission(FilmCommissionId: $filmCommissionId) {
						id
						name
						description
						webSite
						xidLogo
						filmCommissionContact {
							id
							name
							firstName
							phone
							email
							function
						}
					}
				}
			`,
			variables: {
				filmCommissionId: parseInt(filmCommissionId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetFilmCommission;
	} catch (e) {
		console.log({ e });
	}
};

export const getFilmCommissions = async () => {
	try {
		const {
			data: { GetFilmCommissions }
		} = await apollo.query({
			query: gql`
				query {
					GetFilmCommissions {
						id
						name
						description
						webSite
						xidLogo
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});

		return GetFilmCommissions;
	} catch (e) {
		console.log({ e });
	}
};
