var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          [
            _c(
              "b-card",
              { attrs: { "no-body": "" } },
              [
                _c(
                  "b-card-header",
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "6" } }, [
                          _c("h4", [
                            _vm._v(
                              _vm._s(
                                _vm.action === 0
                                  ? _vm.FormMSG(2, "Add Commission")
                                  : _vm.FormMSG(3, "Update Commission")
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          { staticClass: "pull-right", attrs: { cols: "6" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "btn btn-primary btn-md float-right",
                                style: `${!_vm.$screen.md ? "width:100%" : ""}`,
                                attrs: { variant: "success", size: "md" },
                                on: { click: _vm.backToList },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(1, "Back to list")) +
                                    " "
                                ),
                                _c("i", { staticClass: "icon-action-undo" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card-body",
                  [
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.onSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "9" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(4, "Name"),
                                      "label-for": "name",
                                      "label-cols": 3,
                                      horizontal: true,
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid":
                                          _vm.isSubmitted &&
                                          _vm.$v.commission.name.$error,
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.FormMSG(
                                          5,
                                          "Your commission name..."
                                        ),
                                      },
                                      model: {
                                        value: _vm.commission.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.commission,
                                            "name",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "commission.name",
                                      },
                                    }),
                                    _vm.isSubmitted &&
                                    !_vm.$v.commission.name.required
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    16,
                                                    "Please, length should be at least 2"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(6, "Description"),
                                      "label-for": "Description",
                                      "label-cols": 3,
                                      horizontal: true,
                                    },
                                  },
                                  [
                                    _c("b-form-textarea", {
                                      class: {
                                        "is-invalid":
                                          _vm.isSubmitted &&
                                          _vm.$v.commission.description.$error,
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.FormMSG(
                                          7,
                                          "Commission description..."
                                        ),
                                        rows: "5",
                                      },
                                      model: {
                                        value: _vm.commission.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.commission,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "commission.description",
                                      },
                                    }),
                                    _vm.isSubmitted &&
                                    !_vm.$v.commission.description.required
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    17,
                                                    "Please, enter some details"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(8, "Web Site"),
                                      "label-for": "webSite",
                                      "label-cols": 3,
                                      horizontal: true,
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.FormMSG(
                                          9,
                                          "https://..."
                                        ),
                                      },
                                      model: {
                                        value: _vm.commission.webSite,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.commission,
                                            "webSite",
                                            $$v
                                          )
                                        },
                                        expression: "commission.webSite",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "clearfix",
                                    attrs: {
                                      label: _vm.FormMSG(12, "Contacts"),
                                      "label-for": "contacts",
                                      "label-cols": 3,
                                      horizontal: true,
                                    },
                                  },
                                  [
                                    _c("contact-selector", {
                                      attrs: {
                                        "supplier-id": _vm.id,
                                        "list-label": _vm.FormMSG(13, "Manage"),
                                        placeholder: _vm.FormMSG(
                                          14,
                                          "Choose a contact ..."
                                        ),
                                        "modal-title": _vm.FormMSG(
                                          15,
                                          "Manage Contact"
                                        ),
                                        "error-message": _vm.FormMSG(
                                          18,
                                          "Please, enter one or more contact(s)"
                                        ),
                                        "show-default-label": false,
                                        "is-submitted": _vm.isSubmitted,
                                      },
                                      on: {
                                        "contact-selector:invalid":
                                          _vm.isInvalidateFields,
                                        "contact-selector:contacts":
                                          _vm.onSelectedContact,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "w-100" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "pull-right px-0",
                                        attrs: { cols: "3" },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "submit",
                                                  variant: "primary",
                                                  size: "md",
                                                  block: "",
                                                  disabled: _vm.disabled,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(11, "Save")
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "px-4" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        model: {
                                          value: _vm.commission.xidLogo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.commission,
                                              "xidLogo",
                                              $$v
                                            )
                                          },
                                          expression: "commission.xidLogo",
                                        },
                                      },
                                      [
                                        _c("b-img", {
                                          attrs: {
                                            src: _vm.showPicture(
                                              _vm.commission.xidLogo
                                            ),
                                            fluid: "",
                                            alt: "logo",
                                            width: "128",
                                            height: "128",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "button",
                                              variant: "outline-primary",
                                              size: "md",
                                              block: "",
                                            },
                                            on: { click: _vm.openLogoModal },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(10, "Your logo")
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("logo", {
              attrs: {
                "supplier-attribute-id": _vm.commission.id,
                "image-child-sub-type": "filmCommission",
              },
              on: {
                "supplier-attribute-logo:hidden": _vm.onHideLogoModal,
                "supplier-attribute-logo:added": _vm.onAddLogoModal,
                "supplier-attribute-logo:removed": _vm.onRemoveLogoModal,
              },
              model: {
                value: _vm.isOpenLogoModal,
                callback: function ($$v) {
                  _vm.isOpenLogoModal = $$v
                },
                expression: "isOpenLogoModal",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }